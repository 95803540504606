<template>
  <v-container class="container--fluid text-center">
    <Card>
      <v-card-title class="">
        <span class="blue--text text-h6"> Santral Ekle </span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div class="col-12 d-flex">
          <Button
            class="mt-2"
            :outlined="true"
            :dense="true"
            color="indigo"
            @click="createFarm"
          >
            Santral Ekle
          </Button>
        </div>

        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page.sync="itemPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.status="{item}">
            <Chips
              :payload="{
                color: item.status === 'Active' ? 'green' : 'red',
                text: item.status === 'Active' ? 'Aktif' : 'Pasif',
                textColor: 'white',
                small: true
              }"
            />
          </template>
          <template v-slot:item.santralCode="{item}">
            <v-icon

              class="mr-2"
              @click="editItemOpen(item)"
            >
              mdi-pencil-circle
            </v-icon>
            <v-icon

              @click="deleteItem(item)"
            >
              mdi-delete-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </Card>

    <Dialog
      v-model="dialog"
      :persistent="true"
      max-width="800px"
      width="850px"
    >
      <Card>
        <v-card-title>
          <span class="blue--text"> <h2>Santral Ekle</h2></span>
          <v-spacer />
          <Button
            :elevation="0"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </Button>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-col cols="12 d-flex">
            <v-col cols="6">
              <!--Saha Adı:-->

              <TextField
                ref="farmdata"
                v-model="farmdata.farmName"
                label="Saha Adı:"

                :outlined="true"
                :dense="true"
                :disabled="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />

              <!--Kurulu Güç:-->
              <TextField
                ref="farmdata"
                v-model="santraldata.NominalPlantPower.value"
                label="Kurulu Güç:"
                class=" mb-0 mt-0 pt-0 pb-0"
                :outlined="true"
                :dense="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />

              <!--Modül Sıcaklık Katsayısı:-->
              <TextField
                ref="santraldata"
                v-model="santraldata.ModuleTemperatureCoefficient.value"
                label="Modül Sıcaklık Katsayısı:"
                class=" mb-0 mt-0 pt-0 pb-0"
                :outlined="true"
                :dense="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />

              <!--Durum-->

              <Select
                ref="santraldata"
                v-model="santraldata.status"
                label="Santral Durum:"
                :items="status"
                :dense="true"
                :autofocus="true"
                :outlined="true"
                :hide-details="true"
              />
            </v-col>
            <v-col cols="6">
              <!--Santral Adı:-->
              <TextField
                ref="santraldata"
                v-model="santraldata.santral"
                label="Santral Adı:"
                class=" mb-0 mt-0 pt-0 pb-0"
                :outlined="true"
                :dense="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />

              <!--Kurulu Güç Birimi:-->
              <TextField
                v-model="santraldata.NominalPlantPower.unit"
                label="Kurulu Güç Birimi:"
                class=" mb-0 mt-0 pt-0 pb-0"
                :outlined="true"
                :dense="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />

              <!--Modül Sıcaklık Katsayısı Birimi:-->

              <TextField
                v-model="santraldata.ModuleTemperatureCoefficient.unit"
                label="Modül Sıcaklık Katsayısı Birimi:"
                class=" mb-0 mt-0 pt-0 pb-0"
                :outlined="true"
                :dense="true"
                :hidden-footer="true"
                :hidden-details="true"
                :hidden-hint="true"
                :required="true"
              />
            </v-col>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"
            @click="dialog = false"
          >
            Kapat
          </Button>
          <Button
            v-if="farmEdit === 'false'"
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"
            @click="setfunc"
          >
            Kaydet
          </Button>

          <Button
            v-if="farmEdit === 'true'"
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"
            @click="editFarm"
          >
            Güncelle
          </Button>
        </v-card-actions>
      </Card>
    </Dialog>
    <Snackbar
      :notify="notify"
    />
  </v-container>
</template>

<script>
import { getFarm } from '@/api/Request/farm';
import helper from '@/api/helper';
import {
  setSantralCreate, setSantralEdit, getSantralList, setInverterDelete,
} from '@/api/Request/santral';

export default {
  data: () => ({
    desserts: [],
    itemPerPage: 15,
    dialog: false,
    search: '',
    valid: true,
    lazy: true,
    singleSelect: true,
    edit: false,
    editdialog: false,

    santraldata: {
      santral: '',
      farmName: '',
      timeInterval: '',
      status: 'Active',
      ModuleTemperatureCoefficient: {
        unit: '',
        value: '',
      },
      NominalPlantPower: {
        unit: '',
        value: '',
      },
    },

    farmEdit: 'false',
    companyCode: null,
    prefix: null,
    farmdata: {},
    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },
  }),
  computed: {
    status() {
      return [
        { text: 'Aktif', value: 'Active' },
        { text: 'Pasif', value: 'Passive' },
      ];
    },
    headers() {
      return [
        { text: 'Santral Adı', value: 'santral' },
        { text: 'Kurulu Güç', value: 'NominalPlantPower.value' },
        { text: 'Ekleme Tarihi', value: 'createTime' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'santralCode' },
      ];
    },

  },
  watch: {},
  created() {
    this.selectedFarmFunc();
  },
  mounted() {},
  methods: {
    setStatus(item) {
      return item === 'Active' ? 'Aktif' : 'Pasif';
    },
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;
      this.getFarm();
      this.getSantralList();
    },
    // seçilen saha bilgisini alma
    getFarm() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };
      this.farmdata = [];
      getFarm(params).then((res) => {
        if (!helper.is_Empty(res.data.data)) {
          // eslint-disable-next-line prefer-destructuring
          this.farmdata = res.data.data[0];
        }
      });
    },

    // sahaya baglı santral listesi
    getSantralList() {
      const params1 = {
        condiniton: {
          prefix: this.prefix,
        },
      };
      this.desserts = [];
      getSantralList(params1).then((res) => {
        if (res.data.success === 'true') {
          this.desserts = res.data.data;
        }
      });
    },

    deleteItem(item) {
      const param = {
        condiniton: {
          id: item.id,
        },
      };
      setInverterDelete(param).then(() => {
        this.selectedFarmFunc();
      });
    },

    setfunc() {
      const params = {
        data: this.santraldata,
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };

      setSantralCreate(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = res.data.message;
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        setTimeout(() => {
          this.selectedFarmFunc();
        }, 500);
      });
    },
    createFarm() {
      this.santraldata = {
        santral: '',
        farmName: this.farmdata.farmName,
        installedPower: '',
        timeInterval: '',
        status: 'Active',
        ModuleTemperatureCoefficient: {
          unit: '',
          value: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };

      this.dialog = true;
      this.farmEdit = 'false';
    },
    editItemOpen(item) {
      this.santraldata = item;
      this.santraldata.farmName = this.farmdata.farmName;
      this.santraldata = {
        santral: '',
        farmName: this.farmdata.farmName,

        timeInterval: '',
        status: 'Active',
        ModuleTemperatureCoefficient: {
          unit: '',
          value: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };
      this.santraldata = { ...this.santraldata, ...item };
      this.farmEdit = 'true';
      this.dialog = true;
    },

    editFarm() {
      this.santraldata.farmName = this.farmdata.farmName;
      this.santraldata.companyCode = this.companyCode;

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          santralCode: this.santraldata.santralCode,
        },
        data: this.santraldata,
      };

      setSantralEdit(params).then(() => {
        // console.log(res);
        this.selectedFarmFunc();
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
